<!-- @format -->

<template>
  <div>
    <h4>My Team Members: {{ count }}</h4>
    <h4>My Super Team Members: {{ all }}</h4>
    <vue-ads-table :columns="columns" :rows="rows" :page="page" @page-change="pageChanged">
      <template slot="toggle-children-icon" slot-scope="props">
        <div v-if="props.expanded">
          <feather-icon size="24" icon="MinusIcon" style="box-shadow: 0 0 10px #ccc" />
        </div>
        <div v-else>
          <feather-icon size="24" icon="PlusIcon" />
        </div>
      </template>
      <template slot="id" slot-scope="props">
        <div :style="{color: props.row._children.length > 0 ? 'green' : '#000'}">
          <span style="color: red">
            {{ props.row.dai ? `[ ${props.row.dai} ]` :'' }}
          </span>
          {{ props.row.id }}
          <span :style="{width: 20 * (props.row.dai - 1) + 'px'}" class="offset-span" />
          <b-avatar button :src="props.row.avatar" @click="detailWindow(props.row.id)" />
        </div>
      </template>
      <template slot="real_name" slot-scope="props">
        <!-- <span style="color: red">
          {{ props.row.dai ? `[ ${props.row.dai} ]` :'' }}
        </span> -->
        <div @click="toReport(props.row.real_name)">
          {{ props.row.real_name }}
        </div>
      </template>
      <template slot="level" slot-scope="props">{{ props.row.level.name }}</template>
    </vue-ads-table>

    <b-modal
      id="getActiveTeamTp"
      ref="modal"
      title="Details"
      class="details-toast"
    >
      <div class="window-avatar">
        <b-avatar :src="row.avatar" size="60" />
        <div class="window-name-id">
          <p class="window-name">{{ row.real_name }}</p>
          <p>{{ row.id }}</p>
        </div>
      </div>
      <p><span class="window-label">Level:</span>{{ row.level_name }}</p>
      <p><span class="window-label">Mobile:</span>{{ row.mobile }}</p>
      <p><span class="window-label">Email:</span>{{ row.email }}</p>

    </b-modal>

  </div>
</template>

<script>
import VueAdsTable from 'vue-ads-table-tree'
import 'vue-ads-table-tree/dist/vue-ads-table-tree.css'
import axiosIns from '@/libs/axios'
import { BAvatar, BModal } from 'bootstrap-vue'

export default {
  name: 'BasicTableApp',
  components: {
    VueAdsTable,
    BAvatar,
    BModal
  },
  data() {
    return {
      count: 0,
      page: 0,
      filter: '',
      all: '',
      columns: [
        {
          title: 'Selection',
          collapseIcon: true,
        },
        {
          property: 'real_name',
          title: 'Agent Name',
          // collapseIcon: true,
        },
        {
          property: 'level',
          title: 'Agent Level',
        },
        {
          title: 'Agent ID',
          property: 'id',
        },
        // {
        //   property: 'created_at',
        //   title: 'Register Time',
        // },
      ],
      rows: [],
      row: {
        avatar: '',
        real_name: '',
        id: '',
        level_name: '',
        mobile: '',
        email: '',
      },
      name: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fetchData()
      document.querySelector('input.vue-ads-p-2').style.display = 'none'
    })
  },
  methods: {
    detailWindow(id) {
      axiosIns.get(`/user/get-u?user_id=${id}`).then(res => {
        console.log('res', res)
        this.row.level_name = res.data.level.name
        this.row = { ...this.row, ...res.data }
        this.$bvModal.show('getActiveTeamTp')
      }).catch(err => {
      })
    },
    toReport(val) {
      this.$router.push({
        path: '/vip/analytics',
        query: {
          name: val,
        },
      })
    },
    // 递归计算树菜单一共出现多少个节点
    countNodes(data) {
      let count = 0
      data.forEach(item => {
        if (item._children) {
          count += this.countNodes(item._children)
        } else {
          count++
        }
      })
      return count
    },
    // 将树展开为一维数组
    flatten(data) {
      const result = []
      data.forEach(item => {
        if (item._children) {
          result.push(...this.flatten(item._children))
        } else {
          result.push(item)
        }
      })
      return result
    },

    // filterChanged(filter) {
    //   this.name = filter
    //   this.fetchData()
    //   // this.filter = filter
    // },
    fetchData() {
      axiosIns.get(`/user/childrenUser2?name=${this.name}`).then(response => {
        // 递归 替换树结构中所有 child 字段为 _children 字段
        const result = function (data) {
          const newData = []
          data.forEach((item, index) => {
            if (item.child) {
              item._children = result(item.child)
              item._showChildren = false
              delete item.child
            }
            newData.push(item)
          })
          return newData
        }
        this.rows = result(response.data.list ? response.data.list : response.data)
        // this.count = this.countNodes(this.rows)
        this.count = response.data.child
        this.all = response.data.all || 0
      })
    },

    pageChanged(page) {
      this.page = page
    },
  },
}
</script>

<style lang="scss" scoped>
.offset-span {
  display: inline-block;
}

.window-avatar {
  display: flex;
font-size: 1rem;
  margin-bottom: 10px;
}
.window-name-id {
  margin-left: 10px;
  .window-name {
    font-size: 1.5rem;
    margin: 0 0 4px;
  }
}
.window-label {
  text-align: right;
  width: 60px;
  display: inline-block;
  margin-right: 10px;
}
</style>
